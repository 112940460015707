/** @jsx jsx */
import { jsx } from '@emotion/core'
import { motion, AnimatePresence } from 'framer-motion'

import { RouterLink } from '../components/content/Link'
import { media, colors, rodoEaseValues } from '../constants'

import headerLogoLarge from '../images/header-logo-large.svg'
import headerLogoSmall from '../images/header-logo-small.svg'
import headerWordMark from '../images/header-wordmark.svg'

const Header = ({ onToggleMenu, menuOpen, showMenuButton, showWordmark }) => (
	<div>
		<div
			css={{
				zIndex: 10000,
				display: 'block',
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '4rem',
				backgroundColor: colors.white,
				transition: 'background-color 175ms linear 1ms', // match body transition
				[media.mediumUp]: {
					display: 'none',
				},
			}}
		/>
		<div
			css={{
				zIndex: 10000,
				position: 'fixed',
				top: 0,
				left: 0,
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '1rem',
				height: '4rem',
				[media.large]: {
					height: '6rem',
					paddingLeft: '2rem',
				},
			}}
		>
			<div
				css={{
					display: 'block',
					[media.large]: {
						display: 'none',
					},
				}}
			>
				<RouterLink to="/" data-comp="header-logo">
					<img
						css={{
							display: 'block',
						}}
						src={headerLogoSmall}
						alt="Free Association"
					/>
				</RouterLink>
			</div>

			<AnimatePresence>
				{!showWordmark && (
					<motion.div
						css={{
							position: 'absolute',
							display: 'none',
							[media.large]: {
								display: 'inline-block',
							},
						}}
						key="glyph"
						variants={{
							enter: {
								opacity: 0,
								translateY: '-1rem',
								transition: {
									duration: 0,
								},
							},
							visible: {
								opacity: 1,
								translateY: '0rem',
								transition: {
									translateY: {
										ease: rodoEaseValues,
										duration: 0.7,
									},
									opacity: {
										ease: 'linear',
										duration: 0.4,
										delay: 0.3,
									},
								},
							},
							exit: {
								opacity: 0,
								translateY: '-1rem',
								transition: {
									translateY: {
										ease: rodoEaseValues,
										duration: 0.6,
									},
									opacity: {
										ease: 'linear',
										duration: 0.3,
									},
								},
							},
						}}
						initial="enter"
						animate="visible"
						exit="exit"
					>
						<RouterLink to="/" data-comp="header-logo">
							<img
								css={{
									display: 'block',
								}}
								src={headerLogoLarge}
								alt="Free Association"
							/>
						</RouterLink>
					</motion.div>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{showWordmark && (
					<motion.div
						css={{
							position: 'absolute',
							display: 'none',
							[media.large]: {
								display: 'block',
							},
						}}
						key="wordmarlk"
						variants={{
							enter: {
								opacity: 0,
								translateY: '1rem',
								transition: {
									duration: 0,
								},
							},
							visible: {
								opacity: 1,
								translateY: '0rem',
								transition: {
									translateY: {
										ease: rodoEaseValues,
										duration: 0.7,
									},
									opacity: {
										ease: 'linear',
										duration: 0.4,
										delay: 0.25,
									},
								},
							},
							exit: {
								opacity: 0,
								translateY: '1rem',
								transition: {
									translateY: {
										ease: rodoEaseValues,
										duration: 0.6,
									},
									opacity: {
										ease: 'linear',
										duration: 0.3,
									},
								},
							},
						}}
						initial="enter"
						animate="visible"
						exit="exit"
					>
						<RouterLink to="/" data-comp="header-logo">
							<img
								css={{
									display: 'block',
								}}
								src={headerWordMark}
								alt="Free Association"
							/>
						</RouterLink>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
		<div
			css={{
				zIndex: 10000,
				position: 'fixed',
				top: 0,
				right: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				paddingRight: '1rem',
				height: '4rem',
				[media.large]: {
					height: '6rem',
					paddingRight: '2rem',
				},

				transition: 'opacity 200ms linear',
				opacity: showMenuButton ? 1 : 0,
				pointerEvents: showMenuButton ? 'auto' : 'none',
			}}
		>
			<MenuButton onClick={onToggleMenu} menuOpen={menuOpen} />
		</div>
	</div>
)

export default Header

const menuAnimDuration = 0.195
const menuAnimDelay = 0.14

const menuTopVariants = {
	closed: {
		y: 3,
		rotate: '0deg',
		transition: {
			rotate: { ease: 'easeInOut', duration: menuAnimDuration },
			y: {
				ease: 'easeInOut',
				duration: menuAnimDuration,
				delay: menuAnimDelay,
			},
		},
	},
	open: {
		y: 8,
		rotate: '135deg',
		transition: {
			y: { ease: 'easeInOut', duration: menuAnimDuration },
			rotate: {
				ease: 'easeInOut',
				duration: menuAnimDuration,
				delay: menuAnimDelay,
			},
		},
	},
}

const menuBottomVariants = {
	closed: {
		y: -3,
		rotate: '0deg',
		transition: {
			rotate: {
				ease: 'easeInOut',
				duration: menuAnimDuration,
			},
			y: {
				ease: 'easeInOut',
				duration: menuAnimDuration,
				delay: menuAnimDelay,
			},
		},
	},
	open: {
		y: -8,
		rotate: '45deg',
		transition: {
			y: {
				ease: 'easeInOut',
				duration: menuAnimDuration,
			},
			rotate: {
				ease: 'easeInOut',
				duration: menuAnimDuration,
				delay: menuAnimDelay,
			},
		},
	},
}

const MenuButton = ({ menuOpen, ...props }) => {
	return (
		<button
			aria-label={menuOpen ? 'Close navigation menu' : 'Open navigation menu'}
			css={{
				cursor: 'pointer',
				width: '2.5rem',
				height: '2.5rem',
				transform: 'translateX(0.625rem)', // make visual area match logo offset
			}}
			{...props}
		>
			<div
				css={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					css={{
						width: '1.25rem',
						height: '1.25rem',
						position: 'relative',
						display: 'block',
						border: 'transparent 1px solid', // make the dims odd
					}}
				>
					<motion.div
						variants={menuTopVariants}
						animate={menuOpen ? 'open' : 'closed'}
						css={{
							display: 'block',
							position: 'absolute',
							left: 0,
							top: 0,
							width: '100%',
							height: 2,
							background: colors.ink,
						}}
					/>
					<motion.div
						variants={menuBottomVariants}
						animate={menuOpen ? 'open' : 'closed'}
						css={{
							display: 'block',
							position: 'absolute',
							left: 0,
							bottom: 0,
							width: '100%',
							height: 2,
							background: colors.ink,
						}}
					/>
				</div>
			</div>
		</button>
	)
}
